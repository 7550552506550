import type { Req } from "@/hl-common/types/api/routes";
import * as Sentry from "@sentry/browser";
import queryStringify from "qs-stringify";
import { logout } from "./client";

export const backendUrl = (): string => {
  // server-side fetches in dev need a different backend
  // TODO: consider removing this hack by using domain names in
  // local dev that resolve the same inside or outside of the container.
  // This may also require some hosts file hacking on developer machines
  // Integration tests might be a model for this...

  if (
    process.env.NEXT_PUBLIC_NODE_ENV === "development" &&
    typeof window === "undefined"
  ) {
    return "http://haven:2337";
  }

  return process.env.NEXT_PUBLIC_HAVEN_URL || "";
};

// assigns params with matched keys to the placeholders in the url
// e.g. /courses/:courseId with params { courseId: 3 } becomes /courses/3
// throws if there is a param in the path that can't be replaced
export const replaceParams = (path: string, params?: Record<string, any>) => {
  if (!params) return path;

  let url = path;
  const placeholders = path.match(/:[a-zA-Z]+/g) || [];

  for (const placeholder of placeholders) {
    const key = placeholder.slice(1); // Remove the leading ':'
    if (!(key in params)) {
      throw new Error(`Missing parameter: ${key}`);
    }
    url = url.replace(placeholder, params[key].toString());
  }

  return url;
};

// takes a Req and munges path params, query params, and body
// and makes a request to the backend
export const _request = async (
  req: Req,
  args?: {
    params?: Record<string, any>;
    query?: Record<string, any>;
    body?: object | FormData;
  },
) => {
  const { params, query, body } = args || {};

  let url = replaceParams(req.path, params);

  // attach query params
  if (query) {
    url += `?${queryStringify(query)}`;
  }

  return _fetch(
    req.method,
    url,
    body,
    req.method === "GET" || req?.canRetry || false,
  );
};

const _fetch = async (
  method: string,
  url: string,
  body: object | FormData | undefined,
  canRetry: boolean,
): Promise<any> => {
  const fullUrl = `${backendUrl()}/api${url}`;

  // adjust body and headers for common cases
  const isFormData = body instanceof FormData;

  return wrappedFetch(
    fullUrl,
    {
      method,
      body: isFormData ? body : JSON.stringify(body),
      credentials: "include",
      headers: {
        // CSRF prevention
        // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet#Use_of_Custom_Request_Headers
        "X-Requested-With": "XMLHttpRequest",
        // Important: Only add Content-Type if not FormData, otherwise it breaks multipart uploads
        // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects
        ...(isFormData ? {} : { "Content-Type": "application/json" }),
      },
    },
    canRetry,
  );
};

// wrappedFetch is a wrapper around fetch that adds:
// - auth and other headers to the options
// - a dev delay to simulate loading states
// - retries for network errors
// - response parsing and error handling for known error types
const wrappedFetch = async (
  input: RequestInfo | URL,
  options: RequestInit,
  canRetry: boolean,
) => {
  return parseAndHandleErrors(
    withRetry(() => withDevDelay(fetch(input, options)), canRetry),
  );
};

// withDevDelay adds delay for local dev to show loading states
const withDevDelay = async <T>(promise: Promise<T>): Promise<T> => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === "development") {
    await new Promise((resolve) =>
      setTimeout(resolve, 400 + Math.random() * 1000),
    );
  }

  return promise;
};

// withRetry re-attempts Failed to fetch errors
// uses a backoff, before finally passing that error up as the result
export const tries = 3;
const backoff = 300;
export const withRetry = async <T>(
  promiseFn: () => Promise<T>,
  canRetry: boolean,
): Promise<T> => {
  if (!canRetry) {
    return promiseFn();
  }

  for (let attempt = 0; attempt < tries; attempt++) {
    try {
      // must `return await` here for the catch block to work
      return await promiseFn();
    } catch (error) {
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        // log failed to fetch retries while logging out the error context
        console.error("Failed to fetch...");

        if (attempt < tries - 1) {
          // delay with backoff
          await new Promise((resolve) =>
            setTimeout(resolve, backoff * 2 ** attempt),
          );
        } else {
          // override "failed to fetch" name for upstream messaging
          throw new Error("Network Error. Please try again later.");
        }
      } else {
        // re-throw other errors
        throw error;
      }
    }
  }

  // should be unreachable - need for compilation...
  throw new Error("Failed to fetch with retries");
};

// detects API errors and returns them in a more consistent format
// also detects network errors for possible upstream retries and better error logging
// also detects invalid credentials to (condtionally) take action on the jwt
const parseAndHandleErrors = async (promise: Promise<Response>) => {
  try {
    const resp = await promise;
    const result = await resp.json();

    // catch and convert strapi errors
    if (isStrapiError(result)) {
      throw new ApiError(
        result.error.message || "Unknown error",
        result.error.status,
      );
    }

    // catch and convert haven errors
    if (isHavenError(result)) {
      throw new ApiError(result.message || "Unknown error", result.statusCode);
    }

    // todo: handle other expected errors - perhaps gcp 5xx?

    return result;
  } catch (error) {
    if (error instanceof ApiError) {
      // check if jwt is expired or malformed
      if (isInvalidCredentialError(error)) {
        // if so, let's clear it...
        await logout();
      }

      // throw again for upstream handling of ApiError
      throw error;
    }

    // report non-api errors
    Sentry.captureException(error);

    throw error;
  }
};

// does it have error.messsage and error.status keys?
const isStrapiError = (result: any) => {
  return !!(result?.error?.message && result?.error?.status);
};

// haven error is shaped like:
// { message: string, statusCode: number }
export const isHavenError = (result: any) => {
  if (typeof result === "object" && result !== null) {
    const e = result as {
      message?: string;
      statusCode?: number;
    };
    if (
      e.message !== undefined &&
      e.statusCode !== undefined &&
      e.statusCode >= 400
    ) {
      return true;
    }
  }

  return false;
};

// match expected error messages for invalid credentials:
// from missing, malformed, or expired jwt
export const isInvalidCredentialError = (error: ApiError) => {
  return (
    error.message === "Invalid credentials" ||
    error.message === "Missing or invalid credentials"
  );
};

// returns a message for expected errors, and captures
// unexpected errors with Sentry before returning a generic error message
export const getErrorMessage = (error: unknown) => {
  if (error instanceof ApiError || error instanceof Error) {
    return error.message;
  }

  return `Unexpected error: ${JSON.stringify(error)}`;
};

// wrapper for *expected* errors from strapi & haven
export class ApiError extends Error {
  public statusCode: number;
  constructor(msg: string, statusCode: number) {
    super();
    this.name = "ApiError";
    this.statusCode = statusCode;
    this.message = msg;
  }
}
